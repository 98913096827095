
import { getStyle } from "@/model/product";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ProductLogo extends Vue {
  @Prop() productKey!: string;
  @Prop() size!: string;

  get productStyle() {
    return getStyle(this.productKey);
  }
}
