
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import DOMPurify from "dompurify";

import MaterialButton from "@/components/MaterialButton.vue";
import AuthorLink from "@/components/AuthorLink.vue";
import HeaderBodyLayout from "@/components/HeaderBodyLayout.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import UIModule from "@/store/ui";
import RepoOrBlogCard from "@/components/RepoOrBlogCard.vue";

import { ALL_PRODUCTS } from "../../../shared/product";
import {
  AuthorData,
  BreadcrumbLink,
  RepoData,
  RepoPage,
  ProductConfig,
} from "../../../shared/types";
import * as util from "../../../shared/util";
import {
  fetchAuthor,
  fetchRepo,
  fetchRepoPage,
  wrapInHolders,
  recommendedRepos,
} from "@/plugins/data";
import { waitForHljsLoad } from "@/plugins/preload";
import { getStyle, ProductStyle } from "@/model/product";

// Global HLJS
// eslint-disable-next-line
declare const hljs: any;

@Component({
  components: {
    MaterialButton,
    AuthorLink,
    HeaderBodyLayout,
    Breadcrumbs,
    RepoOrBlogCard,
  },
})
export default class Repo extends Vue {
  public product!: ProductConfig;
  public repo: RepoData | null = null;
  public content: RepoPage | null = null;
  public authors: AuthorData[] = [];
  public reposRecommended: RepoData[] = [];

  public getBreadcrumbs(): BreadcrumbLink[] {
    return [
      { name: this.product?.name ?? "Product", path: "../" },
      { name: this.repo?.metadata.name ?? "Repo", path: "" },
    ];
  }

  private productKey!: string;
  private id!: string;

  private uiModule = getModule(UIModule, this.$store);

  async mounted() {
    this.productKey = this.$route.params["product"];
    this.id = this.$route.params["repo"];
    this.product = ALL_PRODUCTS[this.productKey];

    const p = this.loadContent();

    this.uiModule.waitFor(p);

    // After content has loaded, highlight all code blocks with HLJS
    p.then(() => waitForHljsLoad()).then(() => {
      hljs && hljs.highlightAll();
    });
  }

  async loadContent() {
    const repo = await fetchRepo(this.productKey, this.id);
    if (repo) {
      this.repo = repo;
    } else {
      this.$router.push("/404");
      return;
    }

    // fetch recommended repo data for the repo
    this.reposRecommended = await recommendedRepos(
      this.productKey,
      {},
      repo.metadata.tags,
      repo.metadata.expertise,
      repo.id
    );

    const authorIds = this.repo.metadata.authorIds || [];
    for (const aid of authorIds) {
      // We don't want a failed author fetch to block the rest of the page rendering
      try {
        const data = await fetchAuthor(aid);
        if (data) {
          this.authors.push(data);
        } else {
          console.warn(`Author not found: ${aid}`);
        }
      } catch (e) {
        console.warn(`Failed to fetch author ${aid}`, e);
      }
    }

    const pagePath =
      this.$route.params["page"] ||
      util.cleanPagePath(this.repo.metadata.content);
    const pageKey = btoa(pagePath);

    const content = await fetchRepoPage(this.productKey, this.id, pageKey);
    if (content) {
      for (const section of content.sections) {
        let indexCursor = 0;
        while (
          section.content
            .substring(indexCursor)
            .includes('src="https://github.com')
        ) {
          const startIndex =
            section.content
              .substring(indexCursor)
              .indexOf('src="https://github.com') +
            indexCursor +
            5;
          const endIndex =
            startIndex + section.content.substring(startIndex).indexOf('"');
          const imageHtml = section.content.substring(startIndex, endIndex);
          const imageExists = await this.imageExists(imageHtml);
          if (!imageExists) {
            const formattedImageHtml = imageHtml
              .replace(
                "https://github.com",
                "https://raw.githubusercontent.com"
              )
              .replace("/blob", "");
            section.content = section.content.replace(
              imageHtml,
              formattedImageHtml
            );
          }
          indexCursor = endIndex;
        }
      }
      this.content = content;
    } else {
      this.$router.push("/404");
      return;
    }
  }

  public async imageExists(imgUrl: string) {
    if (!imgUrl) {
      return false;
    }
    return new Promise((res) => {
      const image = new Image();
      image.onload = () => res(true);
      image.onerror = () => res(false);
      image.src = imgUrl;
    });
  }

  public fullPagePath(path?: string) {
    const base = `/products/${this.productKey}/repos/${this.repo?.id}`;
    if (!path) {
      return base;
    }

    return `${base}/pages/${util.cleanPagePath(path)}`;
  }

  public sanitize(dirty: string) {
    // TODO: Should probably do this on the server for the best security
    return DOMPurify.sanitize(dirty, { USE_PROFILES: { html: true } });
  }

  public async refreshContent() {
    const res = fetch(
      `http://localhost:5001/ugc-site-dev/us-central1/forceRefreshRepo?product=${this.productKey}&id=${this.id}`,
      { mode: "no-cors" }
    );

    this.uiModule.waitFor(res);
    res.then(() => location.reload());
  }

  get productStyle(): ProductStyle {
    return getStyle(this.productKey);
  }

  get showRefreshButton() {
    return process.env.NODE_ENV === "development";
  }

  get loaded() {
    return this.repo != null;
  }

  get projects() {
    return wrapInHolders([], this.reposRecommended).sort((a, b) => {
      return b.data.stats.lastUpdated - a.data.stats.lastUpdated;
    });
  }
}
