
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { waitForMaterialStyles } from "@/plugins/preload";

export interface CheckboxGroupEntry {
  key: string;
  value: string;
  id: string;
  checked: boolean;
}

@Component
export default class CheckboxGroup extends Vue {
  /** Unique string prefix for this form group */
  @Prop() prefix!: string;

  /** User-readable names for the options (ex: Android, Web) */
  @Prop() keys!: string[];

  /** Internal value for each key (ex: android, web) */
  @Prop() values!: string[];

  /** Maximum number of selections allowed */
  @Prop({ default: 100 }) maxSelections!: number;

  @Prop() value!: string;

  @Watch("value", { deep: true })
  public onValueChange(val: any) {
    this.entries = val;
    this.emitValue();
  }

  public entries: CheckboxGroupEntry[] = [];

  async mounted() {
    await waitForMaterialStyles();

    for (let i = 0; i < this.keys.length; i++) {
      const key = this.keys[i];
      const value = this.values[i];

      this.entries.push({
        key,
        value,
        id: this.valueId(value),
        checked: false,
      });
    }

    this.emitValue();
  }

  public valueId(v: string) {
    return `${this.prefix}-${v}`;
  }

  /**
   * Emit the special 'input' event which allows us to use v-model on the group
   */
  public emitValue() {
    this.$emit("input", this.entries);
  }

  get numChecked() {
    return this.entries.filter((e) => e.checked).length;
  }
}
