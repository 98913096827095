
import { Component, Vue, Prop } from "vue-property-decorator";

import { RepoData } from "../../../shared/types";

import MaterialButton from "@/components/MaterialButton.vue";
import TagChip from "@/components/TagChip.vue";
import ProductLogo from "@/components/ProductLogo.vue";
import CircleImage from "@/components/CircleImage.vue";
import { getApiHost } from "@/plugins/data";
import { ColorJson } from "../assets/ts/profile-colors";

import * as dates from "@/plugins/dates";
import * as product from "@/model/product";

@Component({
  components: {
    MaterialButton,
    TagChip,
    ProductLogo,
    CircleImage,
  },
})
export default class LargeRepoCard extends Vue {
  @Prop() repo!: RepoData;
  @Prop({ default: true }) showTags!: boolean;
  @Prop({ default: false }) showLogo!: boolean;

  public authorImageLoaded = false;

  async mounted() {
    if (this.isStale(this.repo.stats.lastUpdated)) {
      document.getElementById(`${this.repo.id}-card`)!.className +=
        " stale-card";
    }
    this.authorImageLoaded = await this.getImage();
  }

  public isStale(lastUpdated: number) {
    const daysAgo = dates.renderDaysAgo(lastUpdated);
    if (daysAgo.includes("months ago")) {
      const monthsAgo = daysAgo.split(" months ago");
      if (parseInt(monthsAgo[0]) > 18) {
        return true;
      }
    }
    return false;
  }

  public renderDaysAgo(lastUpdated: number) {
    return dates.renderDaysAgo(lastUpdated);
  }

  get displayedTags() {
    const filters = this.$route.query.category;
    if (!filters) return this.repo.metadata.tags.slice(0, 4);
    return this.repo.metadata.tags
      .sort((a, b) => {
        if (filters.includes(a) && filters.includes(b)) {
          return a.localeCompare(b);
        } else if (filters.includes(a)) {
          return -1;
        } else if (filters.includes(b)) {
          return 1;
        } else {
          return a.localeCompare(b);
        }
      })
      .slice(0, 4);
  }

  public getTag(value: string, index: number) {
    if (index <= 2) return product.getTag(this.repo.product, value);

    return {
      label: `+${this.repo.metadata.tags.length - 3}`,
      textColor: "text-gray-500",
      bgColor: "bg-gray-50",
    };
  }

  get authorPhotoUrl(): string {
    if (this.authorId) {
      return `${getApiHost()}/api/authorPhoto?id=${this.authorId}`;
    } else if (this.repo.metadata.owner) {
      return `https://avatars.githubusercontent.com/${this.repo.metadata.owner}`;
    }

    return "";
  }

  public async getImage() {
    if (this.authorPhotoUrl) {
      const imageExists = await this.imageExists(this.authorPhotoUrl);
      if (!imageExists) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  public async imageExists(imgUrl: string) {
    if (!imgUrl) {
      return false;
    }
    return new Promise((res) => {
      const image = new Image();
      image.onload = () => res(true);
      image.onerror = () => res(false);
      image.src = imgUrl;
    });
  }

  private getHashCode(text: string): number {
    let hash = 0,
      i,
      chr,
      len;
    if (text.length == 0) return hash;
    for (i = 0, len = text.length; i < len; i++) {
      chr = text.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0;
    }
    return Math.abs(hash);
  }

  get authorId() {
    if (
      this.repo.metadata.authorIds &&
      this.repo.metadata.authorIds.length > 0
    ) {
      return this.repo.metadata.authorIds[0];
    }

    return undefined;
  }

  get link() {
    return `/products/${this.repo.product}/repos/${this.repo.id}`;
  }

  get dynamicAuthorImage() {
    const name = this.repo.metadata.owner.replace(/[().]/gi, "");
    const separatedNames = name?.split(" ");

    let initials = "";
    if (separatedNames && separatedNames?.length > 0) {
      initials += separatedNames[0].charAt(0).toUpperCase();
    }

    const hash = this.getHashCode(initials || "");
    const colorData = ColorJson[hash % ColorJson.length];
    const imageHtml = `<div class="dynamic-author-image-small"
      style="background-color: ${colorData.background}; color: ${colorData.color}">
      ${initials}</div>`;

    return imageHtml;
  }
}
