
import { Component, Prop, Vue } from "vue-property-decorator";

import CircleImage from "@/components/CircleImage.vue";
import { AuthorData } from "../../../shared/types";

@Component({
  components: {
    CircleImage,
  },
})
export default class AuthorLink extends Vue {
  @Prop() author!: AuthorData;
}
