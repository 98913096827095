
import { Component, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import UIModule from "@/store/ui";
import { ALL_PRODUCTS } from "../../../shared/product";

import MaterialButton from "@/components/MaterialButton.vue";
import CircleImage from "@/components/CircleImage.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import { ProductConfig } from "../../../shared/types";
import AuthorFilters from "@/components/AuthorFilters.vue";
import { FirestoreQuery } from "../../../shared/types/FirestoreQuery";

import {
  CheckboxGroupEntry,
} from "@/components/CheckboxGroup.vue";

import { ColorJson } from "../assets/ts/profile-colors";
import { AuthorData, BreadcrumbLink } from "../../../shared/types";
import {
  emptyPageResponse,
  nextPage,
  PagedResponse,
  queryAuthors,
  queryUsingAuthorData,
  fetchFeaturedAuthors
} from "@/plugins/data";

@Component({
  components: {
    MaterialButton,
    CircleImage,
    Breadcrumbs,
    AuthorFilters
  },
})
export default class Authors extends Vue {
  private uiModule = getModule(UIModule, this.$store);

  public getBreadcrumbs(): BreadcrumbLink[] {
    return [{ name: "Authors", path: "" }];
  }

  public filters = {
    types: [] as CheckboxGroupEntry[],
    productAreas: [] as CheckboxGroupEntry[],
  };

  public authorFilter = "";
  public tempAuthorFilter = "";
  public authorSelectFilter = "";
  public showFilterOverlay = false;

  public authorImageLoaded: { [key: string]: boolean } = {};
  public urlParams = new URLSearchParams(window.location.search);

  private pagesToShow = 1;
  public allAuthors: AuthorData[] = [];
  public featuredAuthors: AuthorData[] = [];
  public allAuthorDetailedData = new Map();
  public authorData: PagedResponse<AuthorData> = emptyPageResponse<AuthorData>(
    `/authors`,
    {},
    60
  );

  async mounted() {
    const searchButton = document.getElementById("authorSearchBar");
    searchButton?.addEventListener("keypress", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        document.getElementById("authorSearchButton")?.click();
      }
    });
    const authorData = emptyPageResponse<AuthorData>(
      `/authors`,
      {
        orderBy: [{ fieldPath: "metadata.name", direction: "asc" }],
      },
      60
    );
    const featuredRes = await fetchFeaturedAuthors()
    this.featuredAuthors = featuredRes.docs.map((d) => d.data)
      .sort((a, b) => {
        return a.metadata.name
          .toLowerCase()
          .localeCompare(b.metadata.name.toLowerCase());
      });
    console.log(this.featuredAuthors)
    const authorsPromise = nextPage(authorData);
    const reloadPromise = Promise.all([authorsPromise]).then(async () => {
      this.pagesToShow = 1;
      for (const author of authorData.pages.flatMap((p) => p)) {
        this.authorImageLoaded[author.id] = await this.getImage(author);
      }
      this.authorData = authorData;
    });
    this.uiModule.waitFor(reloadPromise);

    const res = await queryAuthors({
      orderBy: [{ fieldPath: "metadata.name", direction: "asc" }],
    });
    this.allAuthors = res.docs
      .map((d) => d.data)
      .sort((a, b) => {
        return a.metadata.name
          .toLowerCase()
          .localeCompare(b.metadata.name.toLowerCase());
      });
    for (const author of this.allAuthors) {
      this.authorImageLoaded[author.id] = await this.getImage(author);
    }
  }

  public showAuthor(a: AuthorData): boolean {
    if (this.authorFilter.length === 0) {
      return true;
    }

    return a.metadata.name
      .toLowerCase()
      .includes(this.authorFilter.toLowerCase());
  }

  public removeFilterType(value: string) {
    const f = this.filters.types.find((x) => x.value === value);
    if (f) {
      f.checked = false;
    }
  }

  public removeFilterProductAreas(value: string) {
    const f = this.filters.productAreas.find((x) => x.value === value);
    if (f) {
      f.checked = false;
    }
  }

  public resetFilters() {
    for (const c of this.filters.productAreas) {
      c.checked = false;
    }

    for (const t of this.filters.types) {
      t.checked = false;
    }
  }

  get loaded() {
    return this.authors.length > 0;
  }

  get showNoMatchesMessage() {
    return (
      this.authorFilter.length > 0 &&
      !this.allAuthors.some((a) => this.showAuthor(a))
    );
  }

  get hasContent() {
    return this.authorData.currentPage >= 0;
  }

  get canLoadMore() {
    const canLoadMoreRemote = this.authorData.hasNext;

    const canLoadMoreLocal = this.visibleAuthors.length < this.authors.length;

    return canLoadMoreRemote || canLoadMoreLocal;
  }

  get displayedAuthors() {
    if (this.authorFilter != "" || this.authorSelectFilter != "") {
      return this.allAuthors;
    } else {
      return this.visibleAuthors;
    }
  }

  @Watch("queryParams")
  public async onQueryParamsChanged(q: FirestoreQuery) {

    const selectedProducts = []
    if(q.where){
      if(q.where.length > 0){
        for(const condition of q.where) {
          for(const ele of condition.value) {
            selectedProducts.push(ele)
          }
        }
      }
    }

    const onlyTypeFilter = selectedProducts.every(elem => ["open-source", "blog"].includes(elem))

    if(onlyTypeFilter){
      const x= Object.values(ALL_PRODUCTS).sort((a, b) =>
      a.name.localeCompare(b.name)
    );
      for(const ele of x) {
        selectedProducts.push(ele.key)
      }
    }


    if(selectedProducts.length > 0){
      this.authorSelectFilter = "filter"

      let returnOpenSource = false
      let returnBlogs = false

      if(selectedProducts.includes("open-source")){
        returnOpenSource = true
      }
      if(selectedProducts.includes("blog")){
        returnBlogs = true
      }

      if(!returnOpenSource && !returnBlogs){
        returnOpenSource = true
        returnBlogs = true
      }

      const res = await queryUsingAuthorData(selectedProducts, returnBlogs, returnOpenSource)
      this.allAuthors = res
    } else {
      this.authorSelectFilter = ""
    }
  }

  @Watch("filters", { deep: true })
  public async onFiltersTypeChanged() {
    let hasTypeParams = false;
    let hasProductParams = false;
    let typeParams = "";
    let categoryParams = "";
    let url = `?sort=name`;

    for (const filterType of this.filters.types) {
      if (filterType.checked) {
        if (hasTypeParams) {
          typeParams += ",";
        }
        typeParams += `${filterType.value}`;
        hasTypeParams = true;
      }
    }
    for (const filterCategory of this.filters.productAreas) {
      if (filterCategory.checked) {
        if (hasProductParams) {
          categoryParams += ",";
        }
        categoryParams += `${filterCategory.value}`;
        hasProductParams = true;
      }
    }
    if (hasTypeParams) {
      url += `&type=${typeParams}`;
    }
    if (hasProductParams) {
      url += `&productareas=${categoryParams}`;
    }
    window.history.replaceState(null, "", url);
  }

  @Watch("sortBy")
  public onSortByChanged() {
    this.onFiltersTypeChanged()
  }

  public setTempAuthorFilter(event: { target: { value: string } }) {
    this.tempAuthorFilter = event.target.value;
  }

  public async loadMore() {
    const promises = [];

    if (this.authorData.hasNext) {
      promises.push(nextPage(this.authorData));
    }

    await this.uiModule.waitFor(Promise.all(promises));
    this.pagesToShow++;
  }

  public async getImage(author: AuthorData) {
    if (author) {
      const imageExists = await this.imageExists(author.metadata.photoURL);
      if (!imageExists) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  public async imageExists(imgUrl: string) {
    if (!imgUrl) {
      return false;
    }
    return new Promise((res) => {
      const image = new Image();
      image.onload = () => res(true);
      image.onerror = () => res(false);
      image.src = imgUrl;
    });
  }

  private getHashCode(text: string): number {
    let hash = 0,
      i,
      chr,
      len;
    if (text.length == 0) return hash;
    for (i = 0, len = text.length; i < len; i++) {
      chr = text.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0;
    }
    return Math.abs(hash);
  }

  public getDynamicAuthorImage(author: AuthorData) {
    const name = author?.metadata.name.replace(/[().]/gi, "");
    const separatedNames = name?.split(" ");

    let initials = "";
    if (separatedNames && separatedNames?.length > 0) {
      initials += separatedNames[0].charAt(0).toUpperCase();
    }

    const hash = this.getHashCode(initials || "");
    const colorData = ColorJson[hash % ColorJson.length];
    const imageHtml = `<div class="dynamic-author-image-medium"
      style="background-color: ${colorData.background}; color: ${colorData.color}">
      ${initials}</div>`;

    return imageHtml;
  }

  get authors(): AuthorData[] {
    if (this.authorData.pages.length <= 0) {
      return [];
    }
    return this.authorData.pages.flatMap((p) => p);
  }

  get visibleAuthors(): AuthorData[] {
    const maxToShow = 60 * this.pagesToShow;
    return this.authors.slice(0, maxToShow);
  }

  get queryTags(): string[] | null {
    // If no selection, consider them all checked
    const noneCategoryChecked = this.filters.productAreas.every(
      (c) => !c.checked
    );
    if (noneCategoryChecked) {
      return null;
    }

    return this.filters.productAreas.filter((x) => x.checked).map((x) => x.value);
  }


  get queryTypes(): string[] | null {
    // If no selection, consider them all checked
    const noneCategoryChecked = this.filters.types.every(
      (c) => !c.checked
    );
    if (noneCategoryChecked) {
      return null;
    }

    return this.filters.types.filter((x) => x.checked).map((x) => x.value);
  }


  get product(): ProductConfig[] {
    return Object.values(ALL_PRODUCTS).sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }

  get queryParams(): FirestoreQuery {
    const tags = this.queryTags;
    const types = this.queryTypes;

    const q: FirestoreQuery = {orderBy: [],};

    if (tags) {
      if (tags.length > 0) {
        q.where = [
          {
            fieldPath: "metadata.tags",
            operator: "array-contains-any",
            value: tags,
          },
        ];
      }
    }

    if (types) {
      if (types.length > 0) {
        if(q.where){
          q.where.push(
          {
            fieldPath: "metadata.tags",
            operator: "array-contains-any",
            value: types,
          },
        );
        } else {
          q.where = [
          {
            fieldPath: "metadata.tags",
            operator: "array-contains-any",
            value: types,
          },
        ];
        }
      }
    }

    return q;
  }
}
