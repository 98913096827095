
import { Component, Vue } from "vue-property-decorator";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import { BreadcrumbLink } from "../../../shared/types";

@Component({
  components: {
    Breadcrumbs,
  },
})
export default class ContentPolicy extends Vue {
  public getBreadcrumbs(): BreadcrumbLink[] {
    return [{ name: "Content Policy", path: "" }];
  }
}
