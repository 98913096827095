
import { Component, Vue } from "vue-property-decorator";

import MaterialButton from "@/components/MaterialButton.vue";
import { pageView } from "@/plugins/gtag";

@Component({
  components: {
    MaterialButton,
  },
})
export default class CookieBar extends Vue {
  private KEY = "library-google-dev-accept-cookies";

  public show = true;

  mounted() {
    const val = localStorage.getItem(this.KEY);
    const accepted = val === "true";

    this.show = !accepted;

    if (accepted) {
      this.grantConsent();
    }
  }

  public onAccepted() {
    localStorage.setItem(this.KEY, "true");
    this.show = false;
    this.grantConsent();
  }

  public grantConsent() {
    // After optIn make sure we trigger a page view to capture the current page
    pageView(this.$route);
  }
}
