
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import PillGroup from "@/components/PillGroup.vue";
@Component({
    components: {
        PillGroup,
    },
})
export default class FeaturedContentMenu extends Vue {
    @Prop({ default: false }) mobile!: boolean;
    @Prop() value!: { contentGroup: string | string[] };
    public contentGroup = "";
    public filtersChanged = false;
    public defaultFilters = {
        contentGroup: "'November 2023'",
    };
    public loaded = false;

    get filterValues() {
        return {
            contentGroup: this.contentGroup,
        };
    }


    @Watch("value", { deep: true })
    public onValueChange() {
        if (
            Array.isArray(this.value.contentGroup) &&
            this.value.contentGroup.length === 0
        ) {
            this.contentGroup = "";
        }
    }

    @Watch("filterValues", { deep: true })
    public onFilterValuesChange() {
        if (!this.loaded) {
            this.defaultFilters = JSON.parse(JSON.stringify(this.filterValues));
            this.loaded = true;
        }

        if (
            JSON.stringify(this.defaultFilters) != JSON.stringify(this.filterValues)
        ) {
            this.filtersChanged = true;
        } else {
            this.filtersChanged = false;
        }
        this.$emit("input", this.filterValues);
    }
}
