
import { Component, Model, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import MaterialButton from "@/components/MaterialButton.vue";
import LargeRepoCard from "@/components/LargeRepoCard.vue";
import LargeBlogCard from "@/components/LargeBlogCard.vue";
import RepoOrBlogCard from "@/components/RepoOrBlogCard.vue";
import ProductLogo from "@/components/ProductLogo.vue";

import UIModule from "@/store/ui";

import {
  queryRepos,
  queryBlogs,
  shuffleArr,
  wrapInHolders,
} from "@/plugins/data";

import { ALL_PRODUCTS } from "../../../shared/product";
import { BlogData, RepoData } from "../../../shared/types";
import { getPromoVideoMain, getPromoVideoPlaylist } from "../model/videos";
import { FirestoreQuery } from "../../../shared/types/FirestoreQuery";
import { EVENT_BUS, NAME_SHOW_SUBMIT_DIALOG } from "@/plugins/events";
import { conversionEvent } from "@/plugins/gtag";

@Component({
  components: {
    MaterialButton,
    LargeRepoCard,
    LargeBlogCard,
    RepoOrBlogCard,
    ProductLogo,
  },
})
export default class Home extends Vue {
  private uiModule = getModule(UIModule, this.$store);

  public recentBlogs: Record<string, BlogData[]> = {};
  public recentRepos: Record<string, RepoData[]> = {};
  public displayedLatestProducts = 6;

  public newsletterEmail = "";
  public promoVideo = getPromoVideoMain();
  public promoVideoPlaylist = getPromoVideoPlaylist();

  private RECENTLY_ADDED_QUERY: FirestoreQuery = {
    orderBy: [
      {
        fieldPath: "stats.dateAdded",
        direction: "desc",
      },
    ],
    limit: 5,
  };

  async mounted() {
    const promises: Promise<unknown>[] = [];

    // For each product load 2 recent repos and 2 recent blogs
    // TODO: One day this should use Firestore bundles
    for (const product of Object.values(ALL_PRODUCTS)) {
      const blogPromise = this.fetchRecentBlogs(product.key);
      const repoPromise = this.fetchRecentRepos(product.key);
      promises.push(blogPromise, repoPromise);
    }

    this.uiModule.waitFor(Promise.all(promises));
  }

  public async fetchRecentRepos(product: string) {
    const res = await queryRepos(product, this.RECENTLY_ADDED_QUERY);
    const docs = res.docs.map((d) => d.data);

    // We take the most recent 10, shuffle, and pick 3. That way the
    // most recent additions don't get stuck on the homepage.
    const recentRepos = shuffleArr(docs).slice(0, 3);
    Vue.set(this.recentRepos, product, recentRepos);
  }

  public async fetchRecentBlogs(product: string) {
    const res = await queryBlogs(product, this.RECENTLY_ADDED_QUERY);
    const docs = res.docs.map((d) => d.data);

    // We take the most recent 10, shuffle, and pick 3. That way the
    // most recent additions don't get stuck on the homepage.
    const recentBlogs = shuffleArr(docs).slice(0, 3);
    Vue.set(this.recentBlogs, product, recentBlogs);
  }

  public showSubmitDialog() {
    EVENT_BUS.$emit(NAME_SHOW_SUBMIT_DIALOG);
  }

  public incrementDisplayedLatestProducts() {
    this.displayedLatestProducts += 4;
  }

  get hasContent() {
    const hasRepos = Object.values(this.recentRepos).some(
      (arr) => arr.length > 0
    );
    const hasBlogs = Object.values(this.recentBlogs).some(
      (arr) => arr.length > 0
    );

    return hasRepos && hasBlogs;
  }

  get products() {
    return Object.values(ALL_PRODUCTS).sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }

  get productsRandomized() {
    // Calculate the day of the year:
    // https://stackoverflow.com/a/8619946/324977
    const now = new Date();
    const diff = now.getTime() - new Date(now.getFullYear(), 0, 0).getTime();
    const day = Math.floor(diff / (1000 * 60 * 60 * 24));

    const configs = Object.values(ALL_PRODUCTS);

    // Use the day of the year to change the order of product display
    const startInd = day % configs.length;
    return [...configs.slice(startInd), ...configs.slice(0, startInd)];
  }

  get recentProjects() {
    const allRepos = Object.values(this.recentRepos).flatMap((arr) => arr);
    const allBlogs = Object.values(this.recentBlogs).flatMap((arr) => arr);

    const projects = wrapInHolders(allBlogs, allRepos);

    // Add 30m of jitter so that the exact time the server added this doesn't
    // matter so much.
    for (const p of projects) {
      p.data.stats.dateAdded +=
        Math.random() * 1000 * 60 * 15 * Math.round(Math.random() - 1);
    }

    return projects
      .sort((a, b) => {
        return b.data.stats.dateAdded - a.data.stats.dateAdded;
      })
      .slice(0, 14);
  }

  get loading() {
    return this.uiModule.loading;
  }

  get newsletterLink() {
    conversionEvent("subscribe");
    return `https://docs.google.com/forms/d/e/1FAIpQLSemI2L4-6KCt0Pbze4sxBMLjXdo8Q3YukHg_dSEhdgb9njtgQ/viewform?usp=pp_url&entry.174388885=${this.newsletterEmail}`;
  }
}
