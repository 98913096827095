
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import UIModule from "@/store/ui";

import MaterialButton from "@/components/MaterialButton.vue";
import RepoOrBlogCard from "@/components/RepoOrBlogCard.vue";
import HeaderBodyLayout from "@/components/HeaderBodyLayout.vue";
import CircleImage from "@/components/CircleImage.vue";
import AuthorExpertiseCard from "@/components/AuthorExpertiseCard.vue";
import { ColorJson } from "../assets/ts/profile-colors";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

import {
  fetchAuthor,
  queryAuthorProjects,
  wrapInHolders,
} from "@/plugins/data";
import {
  AuthorData,
  BlogData,
  BreadcrumbLink,
  RepoData,
} from "../../../shared/types";

@Component({
  components: {
    MaterialButton,
    RepoOrBlogCard,
    HeaderBodyLayout,
    CircleImage,
    AuthorExpertiseCard,
    Breadcrumbs,
  },
})
export default class Author extends Vue {
  private uiModule = getModule(UIModule, this.$store);

  public getBreadcrumbs(): BreadcrumbLink[] {
    return [
      { name: "Authors", path: "./" },
      { name: this.author?.metadata?.name ?? "Author", path: "" },
    ];
  }

  public id!: string;
  public author: AuthorData | null = null;
  public authorImageLoaded = false;

  public blogs: BlogData[] = [];
  public repos: RepoData[] = [];

  mounted() {
    this.id = this.$route.params["author"];

    // Re-route to the lowercase author ID
    if (this.id !== this.id.toLowerCase()) {
      this.$router.push(`/authors/${this.id.toLowerCase()}`);
      return;
    }

    this.uiModule.waitFor(this.loadContent());
  }

  public async loadContent() {
    const author = await fetchAuthor(this.id);
    if (!author) {
      this.$router.push("/404");
      return;
    }
    const { blogs, repos } = await queryAuthorProjects(this.id);

    this.blogs = blogs.docs.map((d) => d.data);
    this.repos = repos.docs.map((d) => d.data);
    this.author = author;

    this.authorImageLoaded = await this.getImage();
  }

  public async getImage() {
    if (this.author) {
      const imageExists = await this.imageExists(this.author.metadata.photoURL);
      if (!imageExists) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  public async imageExists(imgUrl: string) {
    if (!imgUrl) {
      return false;
    }
    return new Promise((res) => {
      const image = new Image();
      image.onload = () => res(true);
      image.onerror = () => res(false);
      image.src = imgUrl;
    });
  }

  private getHashCode(text: string): number {
    let hash = 0,
      i,
      chr,
      len;
    if (text.length == 0) return hash;
    for (i = 0, len = text.length; i < len; i++) {
      chr = text.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0;
    }
    return Math.abs(hash);
  }

  get loaded() {
    return this.author != null;
  }

  get bio() {
    if (this.author) {
      if (this.author.metadata.bio.length > 0) {
        return this.author.metadata.bio;
      }
    }

    return "Dev Library contributor";
  }

  get dynamicAuthorImage() {
    const name = this.author?.metadata.name.replace(/[().]/gi, "");
    const separatedNames = name?.split(" ");

    let initials = "";
    if (separatedNames && separatedNames?.length > 0) {
      initials += separatedNames[0].charAt(0).toUpperCase();
    }

    const hash = this.getHashCode(initials || "");
    const colorData = ColorJson[hash % ColorJson.length];
    const imageHtml = `<div class="dynamic-author-image"
      style="background-color: ${colorData.background}; color: ${colorData.color}">
      ${initials}</div>`;

    return imageHtml;
  }

  get expertise() {
    const blogProducts = this.blogs.map((b) => b.product);
    const repoProducts = this.repos.map((r) => r.product);
    return Array.from(new Set([...blogProducts, ...repoProducts])).sort();
  }

  get projects() {
    return wrapInHolders(this.blogs, this.repos).sort((a, b) => {
      return b.data.stats.lastUpdated - a.data.stats.lastUpdated;
    });
  }
}
