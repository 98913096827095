
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import UIModule from "@/store/ui";

@Component({
  components: {},
})
export default class ProgressBar extends Vue {
  public uiModule = getModule(UIModule, this.$store);

  get loading() {
    return this.uiModule.loading;
  }
}
