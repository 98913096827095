
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import RadioGroup from "@/components/RadioGroup.vue";
import InfoCircle from "@/components/InfoCircle.vue";
import CheckboxGroup, {
  CheckboxGroupEntry,
} from "@/components/CheckboxGroup.vue";
import { ProductConfig } from "../../../shared/types";
import MaterialButton from "@/components/MaterialButton.vue";

@Component({
  components: {
    RadioGroup,
    CheckboxGroup,
    InfoCircle,
    MaterialButton,
  },
})
export default class ProjectFilters extends Vue {
  @Prop() product!: ProductConfig;
  @Prop({ default: false }) mobile!: boolean;
  @Prop() value!: { expertiseLevel: string | string[] };

  public types: CheckboxGroupEntry[] = [];
  public categories: CheckboxGroupEntry[] = [];
  public expertiseLevel = "";
  public filtersChanged = false;
  public defaultFilters = {
    expertiseLevel: "",
    types: [],
    categories: [],
  };
  public loaded = false;

  get filterValues() {
    return {
      expertiseLevel: this.expertiseLevel,
      types: this.types,
      categories: this.categories,
    };
  }

  public resetFilters() {
    this.expertiseLevel = this.defaultFilters.expertiseLevel;
    this.types = JSON.parse(JSON.stringify(this.defaultFilters.types));
    this.categories = JSON.parse(
      JSON.stringify(this.defaultFilters.categories)
    );
    this.filtersChanged = false;
  }

  @Watch("value", { deep: true })
  public onValueChange() {
    if (
      Array.isArray(this.value.expertiseLevel) &&
      this.value.expertiseLevel.length === 0
    ) {
      this.expertiseLevel = "";
    }
  }

  @Watch("filterValues", { deep: true })
  public onFilterValuesChange() {
    if (!this.loaded) {
      this.defaultFilters = JSON.parse(JSON.stringify(this.filterValues));
      this.loaded = true;
    }

    if (
      JSON.stringify(this.defaultFilters) != JSON.stringify(this.filterValues)
    ) {
      this.filtersChanged = true;
    } else {
      this.filtersChanged = false;
    }
    this.$emit("input", this.filterValues);
  }
}
