
import { Component, Vue, Watch } from "vue-property-decorator";

import MaterialButton from "@/components/MaterialButton.vue";
import { ALL_PRODUCTS } from "../../../shared/product";
import { EVENT_BUS, NAME_SHOW_SUBMIT_DIALOG } from "@/plugins/events";

@Component({
  components: {
    MaterialButton,
  },
})
export default class NavBar extends Vue {
  public showSideMenu = false;
  public showProductsDropdown = false;

  public showSubmitDialog() {
    EVENT_BUS.$emit(NAME_SHOW_SUBMIT_DIALOG);
  }

  get products() {
    return Object.values(ALL_PRODUCTS).sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }

  @Watch("$route")
  onRouteChange() {
    this.showSideMenu = false;
  }
}
