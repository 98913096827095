
import { Component, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import {
  BlogData,
  RepoData,
  BlogOrRepoDataHolder,
} from "../../../shared/types";

import UIModule from "@/store/ui";

import MaterialButton from "@/components/MaterialButton.vue";
import RepoOrBlogCard from "@/components/RepoOrBlogCard.vue";
import ProjectFilters from "@/components/ProjectFilters.vue";
import ProjectSort, {
  SORT_ADDED,
  SORT_UPDATED,
  SORT_STARS,
} from "@/components/ProjectSort.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import RadioGroup from "@/components/RadioGroup.vue";
import CheckboxGroup, {
  CheckboxGroupEntry,
} from "@/components/CheckboxGroup.vue";
import HeaderBodyLayout from "@/components/HeaderBodyLayout.vue";
import ProductLogo from "@/components/ProductLogo.vue";

import {
  PagedResponse,
  nextPage,
  emptyPageResponse,
  wrapInHolders,
  queryRepos,
  queryBlogs,
} from "@/plugins/data";

import { ProductConfig } from "../../../shared/types";
import { ALL_PRODUCTS } from "../../../shared/product";
import { FirestoreQuery } from "../../../shared/types/FirestoreQuery";
import { BreadcrumbLink } from "../../../shared/types";
import { getStyle, ProductStyle } from "@/model/product";

@Component({
  components: {
    MaterialButton,
    RepoOrBlogCard,
    RadioGroup,
    CheckboxGroup,
    HeaderBodyLayout,
    ProductLogo,
    ProjectFilters,
    ProjectSort,
    Breadcrumbs,
  },
})
export default class Product extends Vue {
  private uiModule = getModule(UIModule, this.$store);

  public getBreadcrumbs(): BreadcrumbLink[] {
    return [{ name: this.product.name, path: "" }];
  }

  public productLoaded = false;
  public urlParams = new URLSearchParams(window.location.search);
  public showFilterOverlay = false;
  public filters = {
    types: [] as CheckboxGroupEntry[],
    categories: [] as CheckboxGroupEntry[],
    expertiseLevel: [] as CheckboxGroupEntry[],
  };
  public sortBy = SORT_ADDED;
  public searchFilter = "";
  public tempSearchFilter = "";

  private pagesToShow = 1;
  private perPage = 12;

  public allRepos: RepoData[] = [];
  public allBlogs: BlogData[] = [];
  public repoData: PagedResponse<RepoData> = emptyPageResponse<RepoData>(
    `/products/${this.product.key}/repos`,
    {},
    this.perPage
  );
  public blogData: PagedResponse<BlogData> = emptyPageResponse<BlogData>(
    `/products/${this.product.key}/blogs`,
    {},
    this.perPage
  );

  mounted() {
    // Loading will be handled by the first "onQueryParamsChanged" firing
    // which will happen when the page loads and the default values hit
    const searchButton = document.getElementById("productSearchBar");
    searchButton?.addEventListener("keypress", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        document.getElementById("productSearchButton")?.click();
      }
    });
  }

  @Watch("queryParams")
  public async onQueryParamsChanged(q: FirestoreQuery) {
    console.log("onQueryParamsChanged", q);

    if (this.searchFilter === "") {
      if (!this.productLoaded) {
        const repoData = await queryRepos(this.product.key, q);
        this.allRepos = repoData.docs.map((d) => d.data);
        const blogData = await queryBlogs(this.product.key, q);
        this.allBlogs = blogData.docs.map((d) => d.data);
      }
      const repoData = emptyPageResponse<RepoData>(
        `/products/${this.product.key}/repos`,
        q,
        this.perPage
      );
      const reposPromise = nextPage(repoData);

      const blogData = emptyPageResponse<BlogData>(
        `/products/${this.product.key}/blogs`,
        q,
        this.perPage
      );
      const blogsPromise = nextPage(blogData);

      const reloadPromise = Promise.all([reposPromise, blogsPromise]).then(
        () => {
          this.pagesToShow = 1;
          this.repoData = repoData;
          this.blogData = blogData;
        }
      );

      this.uiModule.waitFor(reloadPromise).then(() => {
        this.productLoaded = true;
      });
    } else {
      const repoData = await queryRepos(this.product.key, q);
      this.allRepos = repoData.docs.map((d) => d.data);
      const blogData = await queryBlogs(this.product.key, q);
      this.allBlogs = blogData.docs.map((d) => d.data);
    }
  }

  @Watch("productLoaded")
  public async onProductLoadedChanged() {
    const selectedSort = this.urlParams.get("sort");
    const selectedExpertise = this.urlParams.get("expertise");
    const selectedTypes = this.urlParams.get("type");
    const selectedCategories = this.urlParams.get("category");

    if (selectedSort !== null) {
      document.getElementById(`sort-${selectedSort}`)?.click();
    }
    if (selectedExpertise !== null) {
      document.getElementById(`expertiseLevel-${selectedExpertise}`)?.click();
    }
    if (selectedTypes !== null) {
      const selectedTypesArray = selectedTypes.split(",");
      for (const selectedType of selectedTypesArray) {
        document.getElementById(selectedType)?.click();
      }
    }
    if (selectedCategories !== null) {
      const selectedCategoriesArray = selectedCategories.split(",");
      for (const selectedCategory of selectedCategoriesArray) {
        document.getElementById(selectedCategory)?.click();
      }
    }
  }

  @Watch("sortBy")
  public onSortByChanged() {
    if (this.sortBy === SORT_STARS) {
      for (const type of this.filters.types) {
        type.checked = type.value === "open-source";
      }
    }
    this.onFiltersTypeChanged();
  }

  @Watch("filters", { deep: true })
  public async onFiltersTypeChanged() {
    let hasTypeParams = false;
    let hasCategoryParams = false;
    let hasExpertiseParams = false;
    let typeParams = "";
    let categoryParams = "";
    let expertiseParams = "";
    let url = `?sort=${this.sortBy}`;

    if (
      typeof this.filters.expertiseLevel === "string" &&
      this.filters.expertiseLevel != ""
    ) {
      hasExpertiseParams = true;
      expertiseParams += `${this.filters.expertiseLevel}`;
    }
    for (const filterType of this.filters.types) {
      if (filterType.checked) {
        if (hasTypeParams) {
          typeParams += ",";
        }
        typeParams += `${filterType.id}`;
        hasTypeParams = true;
      }
    }
    for (const filterCategory of this.filters.categories) {
      if (filterCategory.checked) {
        if (hasCategoryParams) {
          categoryParams += ",";
        }
        categoryParams += `${filterCategory.id}`;
        hasCategoryParams = true;
      }
    }
    if (hasExpertiseParams) {
      url += `&expertise=${expertiseParams}`;
    }
    if (hasTypeParams) {
      url += `&type=${typeParams}`;
    }
    if (hasCategoryParams) {
      url += `&category=${categoryParams}`;
      this.$route.query.category = categoryParams;
    }
    window.history.replaceState(null, "", url);
  }

  get queryTags(): string[] | null {
    // If no selection, consider them all checked
    const noneCategoryChecked = this.filters.categories.every(
      (c) => !c.checked
    );
    if (noneCategoryChecked) {
      return null;
    }

    return this.filters.categories.filter((x) => x.checked).map((x) => x.value);
  }

  get queryExpertise(): string | null {
    if (this.filters.expertiseLevel == null) {
      return null;
    }
    return this.filters.expertiseLevel.toString();
  }

  get queryOrderBy(): string {
    switch (this.sortBy) {
      case SORT_UPDATED:
        return "stats.lastUpdated";
      case SORT_STARS:
        return "stats.stars";
      case SORT_ADDED:
      default:
        return "stats.dateAdded";
    }
  }

  get queryParams(): FirestoreQuery {
    const orderBy = this.queryOrderBy;
    let tags = this.queryTags;
    const expertise = this.queryExpertise;

    const q: FirestoreQuery = {
      orderBy: [
        {
          fieldPath: orderBy,
          direction: "desc",
        },
      ],
    };

    if (tags) {
      tags = tags?.filter(
        (tag) =>
          tag !== "Beginner" && tag !== "Intermediate" && tag !== "Advanced"
      );
      if (tags.length > 0) {
        q.where = [
          {
            fieldPath: "metadata.tags",
            operator: "array-contains-any",
            value: tags,
          },
        ];
      }
    }
    if (expertise) {
      if (q.where) {
        q.where?.push({
          fieldPath: "metadata.expertise",
          operator: "==",
          value: expertise.toUpperCase(),
        });
      } else {
        q.where = [
          {
            fieldPath: "metadata.expertise",
            operator: "==",
            value: expertise.toUpperCase(),
          },
        ];
      }
    }

    return q;
  }

  get hasContent() {
    return this.blogData.currentPage >= 0 || this.repoData.currentPage >= 0;
  }

  get canLoadMore() {
    const canLoadMoreRemote =
      (this.showBlogPosts && this.blogData.hasNext) ||
      (this.showOpenSource && this.repoData.hasNext);

    const canLoadMoreLocal =
      this.visibleProjects.length < this.sortedProjects.length;

    return canLoadMoreRemote || canLoadMoreLocal;
  }

  get displayedProjects() {
    if (this.searchFilter != "") {
      return this.allSortedProjects.filter((project) => {
        const filter = this.searchFilter.toLowerCase();
        if (project.type === "repo") {
          if (
            project.data.metadata.name.toLowerCase().includes(filter) ||
            project.data.metadata.repo.toLowerCase().includes(filter) ||
            project.data.metadata.owner.toLowerCase().includes(filter) ||
            project.data.metadata.longDescription.toLowerCase().includes(filter)
          ) {
            return project;
          }
        } else {
          if (
            project.data.metadata.author.toLowerCase().includes(filter) ||
            project.data.metadata.title.toLowerCase().includes(filter)
          ) {
            return project;
          }
        }
      });
    } else {
      return this.visibleProjects;
    }
  }

  public setTempSearchFilter(event: { target: { value: string } }) {
    this.tempSearchFilter = event.target.value;
  }

  public async loadMore() {
    const promises = [];

    if (this.repoData.hasNext) {
      promises.push(nextPage(this.repoData));
    }

    if (this.blogData.hasNext) {
      promises.push(nextPage(this.blogData));
    }

    await this.uiModule.waitFor(Promise.all(promises));
    this.pagesToShow++;
  }

  public removeFilterType(value: string) {
    const f = this.filters.types.find((x) => x.value === value);
    if (f) {
      f.checked = false;
    }
  }

  public removeFilterCategory(value: string) {
    const f = this.filters.categories.find((x) => x.value === value);
    if (f) {
      f.checked = false;
    }
  }

  public removeExpertiseLevel() {
    const el = document.getElementById(
      `expertiseLevel-${this.filters.expertiseLevel}`
    ) as HTMLInputElement | null;
    if (el) {
      el.checked = false;
      this.filters.expertiseLevel = [];
    }
  }

  public resetFilters() {
    for (const c of this.filters.categories) {
      c.checked = false;
    }

    this.removeExpertiseLevel();

    for (const t of this.filters.types) {
      t.checked = false;
    }
  }

  get product(): ProductConfig {
    return ALL_PRODUCTS[this.$route.params["product"]];
  }

  get productStyle(): ProductStyle {
    return getStyle(this.$route.params["product"]);
  }

  get showAllTypes() {
    // If nothing is checked, show them all
    return this.filters.types.every((t) => !t.checked);
  }

  get showOpenSource(): boolean {
    return (
      this.showAllTypes ||
      this.filters.types.some((t) => t.value === "open-source" && t.checked)
    );
  }

  get showBlogPosts(): boolean {
    return (
      this.showAllTypes ||
      this.filters.types.some((t) => t.value === "blog" && t.checked)
    );
  }

  get repos(): RepoData[] {
    if (this.repoData.pages.length <= 0) {
      return [];
    }
    return this.repoData.pages.flatMap((p) => p);
  }

  get blogs(): BlogData[] {
    if (this.blogData.pages.length <= 0) {
      return [];
    }
    return this.blogData.pages.flatMap((p) => p);
  }

  get sortedProjects(): BlogOrRepoDataHolder[] {
    const blogs = this.showBlogPosts ? this.blogs : [];
    const repos = this.showOpenSource ? this.repos : [];
    const projects = wrapInHolders(blogs, repos);

    // Locally join and sort
    return projects.sort((a, b) => {
      const dataA = a.data;
      const dataB = b.data;

      if (this.sortBy === SORT_ADDED) {
        return dataB.stats.dateAdded - dataA.stats.dateAdded;
      } else if (this.sortBy === SORT_STARS) {
        if ("stars" in dataA.stats && "stars" in dataB.stats) {
          return dataB.stats.stars - dataA.stats.stars;
        }
        if ("stars" in dataA.stats) {
          return -1;
        }
        if ("stars" in dataB.stats) {
          return 1;
        }
        return 0;
      } else {
        return dataB.stats.lastUpdated - dataA.stats.lastUpdated;
      }
    });
  }

  get allSortedProjects(): BlogOrRepoDataHolder[] {
    const blogs = this.showBlogPosts ? this.allBlogs : [];
    const repos = this.showOpenSource ? this.allRepos : [];
    const projects = wrapInHolders(blogs, repos);

    // Locally join and sort
    return projects.sort((a, b) => {
      const dataA = a.data;
      const dataB = b.data;

      if (this.sortBy === SORT_ADDED) {
        return dataB.stats.dateAdded - dataA.stats.dateAdded;
      } else {
        return dataB.stats.lastUpdated - dataA.stats.lastUpdated;
      }
    });
  }

  get visibleProjects(): BlogOrRepoDataHolder[] {
    // We load up to 1 full page of blogs and 1 full page of repos so
    // that our sort is stable across page loads, but this means we
    // only show part of the loaded data.
    const maxToShow = this.perPage * this.pagesToShow;
    return this.sortedProjects.slice(0, maxToShow);
  }
}
