
import { Component, Vue } from "vue-property-decorator";

import MaterialButton from "@/components/MaterialButton.vue";

import { EVENT_BUS, NAME_SHOW_SUBMIT_DIALOG } from "@/plugins/events";
import { conversionEvent } from "@/plugins/gtag";

@Component({
  components: {
    MaterialButton,
  },
})
export default class SubmitDialog extends Vue {
  public show = false;

  mounted() {
    EVENT_BUS.$on(NAME_SHOW_SUBMIT_DIALOG, () => {
      this.show = true;
    });
  }

  beforeDestroy() {
    EVENT_BUS.$off(NAME_SHOW_SUBMIT_DIALOG);
  }

  goToForm() {
    this.show = false;
    conversionEvent("submit");
    window.open(
      "https://devlibrary.advocu.com/home/applications/form",
      "_blank"
    );
  }
}
