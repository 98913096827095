
import { Component, Prop, Vue } from "vue-property-decorator";
import { BlogOrRepoDataHolder } from "../../../shared/types";

import LargeBlogCard from "@/components/LargeBlogCard.vue";
import LargeRepoCard from "@/components/LargeRepoCard.vue";

@Component({
  components: {
    LargeBlogCard,
    LargeRepoCard,
  },
})
export default class RepoOrBlogCard extends Vue {
  @Prop() project!: BlogOrRepoDataHolder;
  @Prop({ default: true }) showTags!: boolean;
  @Prop({ default: false }) showLogo!: boolean;
}
