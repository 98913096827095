
import { Component, Vue } from "vue-property-decorator";

import MaterialButton from "@/components/MaterialButton.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import { BreadcrumbLink } from "../../../shared/types";
import { EVENT_BUS, NAME_SHOW_SUBMIT_DIALOG } from "@/plugins/events";

@Component({
  components: {
    MaterialButton,
    Breadcrumbs,
  },
})
export default class About extends Vue {
  public getBreadcrumbs(): BreadcrumbLink[] {
    return [{ name: "About", path: "" }];
  }

  public FaqData = new Array(18).fill(false);

  public showSubmitDialog() {
    EVENT_BUS.$emit(NAME_SHOW_SUBMIT_DIALOG);
  }

  public toggleFaq(index: number) {
    Vue.set(this.FaqData, index, !this.FaqData[index]);
  }
}
