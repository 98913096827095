
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { waitForMaterialStyles } from "@/plugins/preload";

export interface PillGroupEntry {
    key: string;
    value: string;
    id: string;
}

@Component
export default class PillGroup extends Vue {
    @Prop() prefix!: string;
    @Prop() keys!: string[];
    @Prop() values!: string[];
    @Prop() value!: string;
    @Prop() startEmpty!: boolean;

    public truncate(data: string, num: number) {
        const res = data.split("").slice(0, num).join("");
        return res;
    }

    @Watch("value")
    public onValueChange(val: string) {
        this.choice = val;
        this.emitValue(val);
    }

    private choice = "";
    public entries: PillGroupEntry[] = [];

    async mounted() {
        await waitForMaterialStyles();
        for (let i = 0; i < this.keys.length; i++) {
            const key = this.keys[i];
            const value = this.values[i];

            this.entries.push({
                key,
                value,
                id: this.valueId(value),
            });
        }

        // Default is the first entry
        if (this.startEmpty === undefined || !this.startEmpty) {
            this.choice = this.entries[0].value;
            this.emitValue(this.choice);
        }
    }

    public onInput(e: InputEvent) {
        const value = (e.target as HTMLInputElement).value;
        if (value) {
            this.emitValue(value);
        }
    }

    /**
     * Emit the special 'input' event which allows us to use v-model on the group
     */
    public emitValue(value: string) {
        this.$emit("input", value);
    }

    public valueId(v: string) {
        return `${this.prefix}-${v}`;
    }
}
