
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import MaterialButton from "@/components/MaterialButton.vue";
import { ProductConfig } from "../../../shared/types";
import { MDCMenu, Corner } from "@material/menu";

export const SORT_UPDATED = "updated";
export const SORT_ADDED = "added";
export const SORT_STARS = "stars";

@Component({
  components: {
    MaterialButton,
  },
})
export default class ProjectFilters extends Vue {
  @Prop() product!: ProductConfig;
  @Prop({ default: false }) mobile!: boolean;
  @Prop() defaultSort!: string;

  public sortLabelMap = new Map([
    [SORT_UPDATED, "Recently Updated"],
    [SORT_ADDED, "Recently Added"],
    [SORT_STARS, "GitHub Stars"],
  ]);
  public menu?: MDCMenu;
  public sortBy = this.defaultSort ?? SORT_UPDATED;

  mounted() {
    this.menu = new MDCMenu(this.$refs.mdcMenu as Element);
    this.menu.setAnchorCorner(Corner.BOTTOM_LEFT);
  }

  @Watch("sortBy")
  public onValueChange() {
    this.$emit("input", this.sortBy);
  }
}
