import { render, staticRenderFns } from "./ProductLogo.vue?vue&type=template&id=37c9c70f&scoped=true&"
import script from "./ProductLogo.vue?vue&type=script&lang=ts&"
export * from "./ProductLogo.vue?vue&type=script&lang=ts&"
import style0 from "./ProductLogo.vue?vue&type=style&index=0&id=37c9c70f&prod&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37c9c70f",
  null
  
)

export default component.exports