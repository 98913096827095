
import { Component, Vue } from "vue-property-decorator";
import NavBar from "@/components/NavBar.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import FooterBar from "@/components/FooterBar.vue";
import CookieBar from "@/components/CookieBar.vue";
import SubmitDialog from "@/components/SubmitDialog.vue";

@Component({
  components: {
    NavBar,
    ProgressBar,
    FooterBar,
    CookieBar,
    SubmitDialog,
  },
})
export default class App extends Vue {}
