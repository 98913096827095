
import { Component, Prop, Vue } from "vue-property-decorator";

import ProductLogo from "@/components/ProductLogo.vue";

import { ALL_PRODUCTS } from "../../../shared/product";
import { AuthorData } from "../../../shared/types";

@Component({
  components: {
    ProductLogo,
  },
})
export default class AuthorExpertiseCard extends Vue {
  @Prop() author!: AuthorData;
  @Prop() expertise!: string[];

  public getProductName(productId: string) {
    return ALL_PRODUCTS[productId].name;
  }
}
