
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import RadioGroup from "@/components/RadioGroup.vue";
import InfoCircle from "@/components/InfoCircle.vue";
import CheckboxGroup, {
  CheckboxGroupEntry,
} from "@/components/CheckboxGroup.vue";
import { ProductConfig } from "../../../shared/types";
import MaterialButton from "@/components/MaterialButton.vue";

@Component({
  components: {
    RadioGroup,
    CheckboxGroup,
    InfoCircle,
    MaterialButton,
  },
})
export default class AuthorFilters extends Vue {
  @Prop() product!: ProductConfig[];
  @Prop({ default: false }) mobile!: boolean;
  @Prop() value!: { expertiseLevel: string | string[] };

  public types: CheckboxGroupEntry[] = [];
  public productAreas: CheckboxGroupEntry[] = [];
  public expertiseLevel = "";
  public filtersChanged = false;
  public defaultFilters = {
    types: [],
    productAreas: [],
  };
  public loaded = false;

  get filterValues() {
    return {
      types: this.types,
      productAreas: this.productAreas,
    };
  }

  public resetFilters() {
    this.types = JSON.parse(JSON.stringify(this.defaultFilters.types));
    this.productAreas = JSON.parse(
      JSON.stringify(this.defaultFilters.productAreas)
    );
    this.filtersChanged = false;
  }

  @Watch("filterValues", { deep: true })
  public onFilterValuesChange() {
    if (!this.loaded) {
      this.defaultFilters = JSON.parse(JSON.stringify(this.filterValues));
      this.loaded = true;
    }

    if (
      JSON.stringify(this.defaultFilters) != JSON.stringify(this.filterValues)
    ) {
      this.filtersChanged = true;
    } else {
      this.filtersChanged = false;
    }
    this.$emit("input", this.filterValues);
  }
}
